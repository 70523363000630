<template>
  <div class="article-box pt110px">
    
   
    <div class="index-news-box">
      <h1>品牌简介</h1>
      <p>
        密尊（英文名：MIZUN）创立于2018年，是一个奢侈品牌。<br>
密尊以「做中国的国际奢侈品牌」为使命，打造中国人的国际奢侈品牌。密尊产品以独特、奢华、尊贵著称，代表身份、地位与尊荣。密尊秉承「客户为尊·需求为宗·品质为上」原则，为客户提供超高端的个人奢侈品订制服务。<br>
品牌简介<br>
密尊，与生俱来的中华文化底蕴，彰显卓尔不群的品质典范。密尊将努力创造中国奢侈品牌独一无二的价值，提升中国奢侈品牌的影响力！<br>
品牌文化<br>
千年文化·百年品牌<br>
密尊矢志将中华数千年的文化精粹，凝聚成可以历经数百年而不衰的品牌，这是密尊的志向，也是密尊的目标。<br>
旗下产品<br>
密尊拥有珠宝、服饰等高端个人定制产品。<br>
御世珠宝系列<br>
尊贵定制、至尊定制、传世定制<br>
御尊华服系列<br>
尊贵定制、至尊定制<br>
      </p>
      
      
    </div>

    
    
  </div>
</template>

<script>
export default {
  components: {
    
  },
  data() {
    return {
      active: 1,
      total: 4,
      headerShow:true,
      showNav:true,
      navShow:true,
      wrapScroll: {},
      
    }
    
  },
  
  created(){
    
    
  },
  mounted() {
    // setTimeout(()=>{
    //   this.initscroll();
    // },20)
    
  },
  methods: {
    goDetail(){
      this.$router.push("/detail");
    },
  },
};
</script>

<style scoped lang="scss">
.article-box{

  .index-news-box{
    padding: 5vh 10%;
    background: #fff;
    line-height: 2;
  }
  
}
</style>
